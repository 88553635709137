<template>
  <div class="card h-100">
    <div class="card-header">
      <img width="48" height="20" src="@/assets/images/integrations/stripe.svg">
    </div>
    <BaseSpinner :loading="loading">
      <div class="card-body">
        <div class="row">
          <div class="col-12 col-lg-6">
            <div class="mb-2">
              <a-checkbox v-model="values.writeAccess">Read and write access</a-checkbox>
            </div>
            <small class="text-muted">
              <p>Only required if you would like Vatstack to maintain your tax rates.</p>
            </small>
          </div>
          <div class="col-12 col-lg-6 text-right">
            <p>
              <a :href="`https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${ clientId }&scope=${ values.writeAccess ? 'read_write' : 'read_only' }`">
                <img width="190" height="33" src="@/assets/images/stripeconnect.png">
              </a>
            </p>
          </div>
        </div>
      </div>
    </BaseSpinner>
  </div>
</template>

<script>
import BaseSpinner from '@/components/Base/BaseSpinner'

export default {
  components: {
    BaseSpinner,
  },

  data() {
    return {
      loading: false,
      values: {
        writeAccess: false,
      },
    }
  },

  computed: {
    clientId() {
      return process.env.VUE_APP_STRIPE_CLIENT_ID
    },
  },

  async mounted() {
    if (!this.$route.query.code) {
      return false
    }

    this.loading = true

    try {
      var { data } = await this.$httpd.post('/user/integrations', {
        code: this.$route.query.code,
        provider: 'stripe',
      })

      this.$store.commit('user', data)
      this.$message.success('Authorization succeeded.')
      this.$router.replace({ name: 'integrations' })
    } catch (err) {
      if (err.response) {
        this.$message.error(err.response.data.message)
      } else {
        this.$message.error(err.message)
      }

      return false
    } finally {
      this.loading = false
    }
  },
}
</script>
