<template>
  <a-button type="primary" ghost size="small" :disabled="disabled" :loading="loading" @click="onClick">
    <i class="fal fa-exchange mr-2"></i> Synchronize
  </a-button>
</template>

<script>
export default {
  props: {
    disabled: Boolean,
    integration: Object,
  },

  data() {
    return {
      loading: false,
    }
  },

  methods: {
    onClick() {
      this.$confirm({
        title: 'Confirm Synchronization',
        content: 'This will synchronize your rates on Stripe with those on Vatstack once. VAT rates for activated regions will be created based on Vatstack’s database of rates.',
        okText: 'Synchronize',
        onOk: async () => {
          await this.onConfirm()
        },
        onCancel: () => {},
      })
    },
    async onConfirm() {
      this.loading = true

      try {
        var { data } = await this.$httpd.put(`/user/integrations/${ this.integration.id }/rates`)
      } catch (err) {
        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          this.$message.error(err.message)
        }

        return false
      } finally {
        this.loading = false
      }

      this.$message.success('VAT rates have been updated.')
    },
  },
}
</script>
