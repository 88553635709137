<template>
  <div>
    <div v-if="$route.query.error" class="alert alert-danger mb-2">
      <strong>Something went wrong:</strong> {{ $route.query.description }}
    </div>
    <div v-if="!$store.getters.isSubscribed" class="alert alert-warning mb-2">
      To add an integration, subscribe to one of our affordable plans.
      <router-link :to="{ name: 'subscription' }" class="ml-2">Upgrade</router-link>
    </div>
    <div v-for="integration in $store.state.user.integrations" :key="integration.id" class="mb-3">
      <IntegrationItem
        :integration="integration"
      />
    </div>
    <div v-if="!$store.state.user.integrations.find(item => item.provider === 'stripe')" class="mb-4">
      <IntegrationStripe />
    </div>
    <small>
      <p class="text-muted">If you wish us to integrate another provider, please write to <a href="mailto:support@vatstack.com?subject=Integration%20request">support@vatstack.com</a> and tell us the software you’re using.</p>
    </small>
  </div>
</template>

<script>
import IntegrationStripe from '@/components/Integration/IntegrationStripe'
import IntegrationItem from '@/components/Integration/IntegrationItem'

export default {
  components: {
    IntegrationStripe,
    IntegrationItem,
  },
}
</script>
