<template>
  <div class="card">
    <div class="card-header d-flex justify-content-between align-items-center">
      <div>
        <img height="20" :src="require(`@/assets/images/integrations/${ integration.provider }.svg`)">
      </div>
      <ul class="list-inline mb-0">
        <li class="list-inline-item">
          <IntegrationItemDelete
            :integration="integration"
          />
        </li>
        <li class="list-inline-item">
          <IntegrationItemSync
            :disabled="typeof integration.rates.vat_inclusive !== 'boolean' || integration.scope === 'read_only'"
            :integration="integration"
          />
        </li>
      </ul>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-lg-3">
          <label class="label">Tax Rates</label>
        </div>
        <div class="col-12 col-lg-9">
          <div class="mb-2">
            <a-radio-group v-model="values.vat_inclusive" :disabled="integration.scope === 'read_only'">
              <a-radio :value="false">VAT-exclusive</a-radio>
              <a-radio :value="true">VAT-inclusive</a-radio>
            </a-radio-group>
          </div>
          <small class="text-muted">
            <p>Elect whether you list prices exclusive or inclusive of VAT. This is an important option for Stripe. Changing this option midway would create additional tax rate objects with the next synchronization.</p>
          </small>
          <div class="mt-4 mb-2">
            <a-checkbox v-model="values.synchronize" :disabled="typeof values.vat_inclusive !== 'boolean' || integration.scope === 'read_only'">Synchronize automatically</a-checkbox>
          </div>
          <small class="text-muted">
            <p>New tax rate objects are created on Stripe when rates change. Vatstack only feeds standard rates and you should archive outdated tax rates yourself.</p>
          </small>
          <div class="mt-4 mb-2">
            <a-checkbox v-model="values.amend_invoice" :disabled="integration.scope === 'read_only'">Amend new invoices</a-checkbox>
          </div>
          <small class="text-muted">
            <p>The applicable tax rate will be added to new Stripe invoices if none was found, and if a place of supply could be identified from the customer address or shipping address.</p>
          </small>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <div class="d-flex justify-content-between align-items-center">
        <div>
          <a-button type="primary" :loading="loading" @click="onSubmit">
            Save
          </a-button>
        </div>
        <div>
          <small>
            <p class="text-muted mb-0">You granted <strong>{{ integration.scope }}</strong> access on {{ authorized }}.</p>
          </small>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IntegrationItemDelete from '@/components/Integration/IntegrationItemDelete'
import IntegrationItemSync from '@/components/Integration/IntegrationItemSync'

export default {
  components: {
    IntegrationItemDelete,
    IntegrationItemSync,
  },

  props: {
    integration: Object,
  },

  data() {
    return {
      loading: false,
      values: {
        amend_invoice: this.integration.rates.amend_invoice,
        synchronize: this.integration.rates.synchronize,
        vat_inclusive: this.integration.rates.vat_inclusive,
      },
    }
  },

  computed: {
    authorized() {
      return this.integration.authorized.slice(0, 10)
    },
  },

  methods: {
    async onSubmit() {
      this.loading = true

      try {
        var { data } = await this.$httpd.put(`/user/integrations/${ this.integration.id }`, this.values)
      } catch (err) {
        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          this.$message.error(err.message)
        }

        return false
      } finally {
        this.loading = false
      }

      this.$store.commit('user', data)
      this.$message.success('Integration settings saved.')
    },
  },
}
</script>
