<template>
  <a-button type="link" size="small" :loading="loading" @click="onClick">
    Disconnect
  </a-button>
</template>

<script>
export default {
  props: {
    integration: Object,
  },

  data() {
    return {
      loading: false,
    }
  },

  methods: {
    onClick() {
      this.$confirm({
        title: 'Confirm Disconnect',
        content: 'This will disconnect Vatstack from your provider. Your data on Vatstack will remain untouched. Continue with the disconnect?',
        okText: 'Disconnect',
        onOk: async () => {
          await this.onConfirm()
        },
        onCancel: () => {},
      })
    },
    async onConfirm() {
      try {
        var { data } = await this.$httpd.delete(`/user/integrations/${ this.integration.id }`)
      } catch (err) {
        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          this.$message.error(err.message)
        }

        return false
      } finally {
        this.loading = false
      }

      this.$store.commit('user', data)
      this.$message.success('Deauthorization succeeded.')
    },
  },
}
</script>
